import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  DateTimeField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  ComputedProperty,
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class Step extends CrudModel {
  protected static _typeLabel = "Step";
  protected static asProperty = "step";
  public static api = {
    path: "steps",
  };
  protected static routeBase = "steps";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Step Number",
        name: "step_num",
      },
    },
    {
      type: StringProperty,
      opts: {
        computed: true,
        label: "Label",
        name: "label",
        sortable: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Prefix",
        name: "prefix",
        description:
          'A step prefix is for character(s) that always appear between this step and the previous step, and are not included in the option identifier. e.g. "-" appears before special plating steps, and the defined options for that step do not inlcude the "-".',
      },
    },

    {
      type: BooleanProperty,
      opts: {
        name: "is_optional",
        label: "Omittable Step",
        description: "Can this step be omitted entirely in a part number?",
      },
    },

    {
      type: BooleanProperty,
      opts: {
        name: "is_wildcard",
        label: "Wildcard Step",
        description:
          "Wildcard steps can have any value of any length. It will be parsed until reaching a valid value for the proceeding step.",
      },
    },

    {
      type: NumberProperty,
      opts: {
        name: "repeatable_num",
        label: "Repeatable Times",
        description:
          "How many times can this step be repeated? Set to 0 if it only appears once. \r\n\r\ne.g. In GG, the Variant1 step appears 4 times, so it is set to repeat 3 times.",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "series",
        label: "Series",
        relatedModel: "Series",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "step_type",
        label: "Step Type",
        relatedModel: "StepType",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "options",
        apiName: "option_ids",
        label: "Options",
        relatedModel: "Option",
        foreignProperty: "step",
      },
    },

    {
      type: ComputedProperty,
      opts: {
        name: "type_is_optional",
        computeValue: () => true,
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringField,
      opts: {
        property: "label",
        userPermissions: UserPermission.Read,
        isPrimaryLabelField: true,
      },
    },
    {
      type: NumberField,
      opts: {
        property: "step_num",
        userPermissions: UserPermission.Read,
        description:
          "To reorder steps, use Reorder Mode while editing the series.",
      },
    },
    {
      type: StringField,
      opts: {
        property: "prefix",
      },
    },

    {
      type: RelationshipField,
      opts: {
        property: "series",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "step_type",
      },
    },

    {
      type: BooleanField,
      opts: {
        property: "is_optional",
        conditional: {
          property: "step_type.is_optional",
          compare: "notEquals",
        },
      },
    },

    {
      type: BooleanField,
      opts: {
        property: "type_is_optional",
        label: "Omittable Step",
        description:
          "This step's Step Type is omittable, so the step must also be omittable.",
        conditional: {
          property: "step_type.is_optional",
        },
        userPermissions: UserPermission.Read,
      },
    },

    {
      type: BooleanField,
      opts: {
        property: "is_wildcard",
      },
    },

    {
      type: NumberField,
      opts: {
        property: "repeatable_num",
      },
    },

    {
      type: RelationshipFieldMany,
      opts: {
        property: "options",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "stepEdit",
        conditional: {
          property: "is_wildcard",
          value: true,
          compare: "notEquals",
        },
        requiresDetachedLabel: true,
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
    },
    {
      id: "expansion",
      fieldSets: [
        {
          id: "0",
          fields: [
            "step_num",
            "step_type",
            "prefix",
            "repeatable_num",
            "is_optional",
            "type_is_optional",
            "is_wildcard",
            "options",
          ],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      columnFields: ["series", "step_num", "step_type"],
      filterFields: ["step_type", "step_num", "series"],
      searchLabel: "Search by name or identifier",
      defaultSort: [
        {
          field: "series",
          order: "asc",
        },
        {
          field: "step_num",
          order: "asc",
        },
      ],
      expansionComponent: {
        name: "ModelLayoutExpansion",
        opts: {
          layoutId: "expansion",
        },
      },
    },
    {
      id: "seriesField",
      inlineEdit: false,
      columnFields: ["step_num", "step_type"],
      ordinalField: "step_num",
      expansionComponent: {
        name: "ModelLayoutExpansion",
        opts: {
          layoutId: "expansion",
        },
      },
    },
  ];
}
