import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  DateTimeField,
  NumberField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class StepType extends CrudModel {
  protected static _typeLabel = "Step Type";
  protected static asProperty = "step_type";
  public static api = {
    path: "step-types",
    fullCache: true,
  };
  protected static routeBase = "step-types";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Name",
        name: "name",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Shortname",
        name: "shortname",
        description:
          "Shortname for use when referencing this step type in conditionals, gold adder pricing formulas, etc.",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_optional",
        label: "Omittable Step",
        description: "Can this step be omitted entirely in a part number?",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true,
      },
    },
    {
      type: StringField,
      opts: {
        property: "shortname",
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "is_optional",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      modalOptions: {
        readmodeDefault: false,
      },
      columnFields: ["name", "shortname"],
      // inlineEdit: true
    },
  ];
}
