import { ConditionalField } from "../fields/ConditionalField";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";
import {
  BooleanField,
  DateTimeField,
  NumberField,
  RelationshipField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  JsonProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";

export class Factor extends CrudModel {
  protected static _typeLabel = "Factor";
  protected static asProperty = "factor";
  public static api = {
    path: "factors",
    fullCache: true
  };
  protected static routeBase = "factors";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "label",
        label: "Label",
        rules: ["required"]
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "legacy_factor_id",
        label: "Legacy Factor ID",
        userPermissions: UserPermission.Read
      }
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_global",
        label: "Global Factor"
      }
    },
    {
      type: BooleanProperty,
      opts: {
        name: "apply_to_direct_matches",
        label: "Applies to Direct Matches",
        description:
          "If the part number is found in the price book directly, should this factor still be applied?"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "additional_leadtime",
        label: "Additional Leadtime"
      }
    },
    {
      type: JsonProperty,
      opts: {
        name: "conditional",
        label: "Conditions"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty1",
        label: "1"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty5",
        label: "5"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty10",
        label: "10"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty25",
        label: "25"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty50",
        label: "50"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty100",
        label: "100"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty250",
        label: "250"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty500",
        label: "500"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty1000",
        label: "1,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty2500",
        label: "2,500"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty5000",
        label: "5,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty10000",
        label: "10000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty25000",
        label: "25,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty50000",
        label: "50,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty100000",
        label: "100,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "qty250000",
        label: "250,000"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Price Book",
        relatedModel: "PriceBook",
        name: "price_book"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringField,
      opts: {
        property: "label",
        isPrimaryLabelField: 2
      }
    },
    {
      type: StringField,
      opts: {
        property: "description"
      }
    },
    {
      type: BooleanField,
      opts: {
        property: "is_global"
      }
    },
    {
      type: BooleanField,
      opts: {
        property: "apply_to_direct_matches"
      }
    },
    {
      type: StringField,
      opts: {
        property: "legacy_factor_id"
      }
    },
    {
      type: RelationshipField,
      opts: {
        property: "price_book",
        isPrimaryLabelField: 1
      }
    },
    {
      type: NumberField,
      opts: {
        property: "additional_leadtime"
      }
    },
    {
      type: ConditionalField,
      opts: {
        property: "conditional"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty1",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty5",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty10",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty25",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty50",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty100",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty250",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty500",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty1000",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty2500",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty5000",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty10000",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty25000",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty50000",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty100000",
        cols: 2
      }
    },
    {
      type: NumberField,
      opts: {
        property: "qty250000",
        cols: 2
      }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      // useDynamicFields: false,
      filterFields: ["price_book"],
      enableDynamicFilters: true,
      defaultSort: [
        {
          field: "created_at",
          order: "desc"
        }
      ]
    }
  ];
}
