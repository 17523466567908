import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class Option extends CrudModel {
  protected static _typeLabel = "Option";
  protected static asProperty = "option";
  public static api = {
    path: "options",
  };
  protected static routeBase = "options";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "identifier",
        maxLength: 8,
        label: "Identifier",
        description:
          "Unique identifier as this option appears in a part number.",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        maxLength: 100,
        label: "Description",
        description:
          "A human-readble description of what this option represents.",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "type",
        label: "Option Type",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "step",
        relatedModel: "Step",
        label: "Step",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "series",
        relatedModel: "Series",
        label: "Series",
        computed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringField,
      opts: {
        property: "identifier",
        isPrimaryLabelField: 2,
      },
    },
    {
      type: StringField,
      opts: {
        property: "description",
      },
    },
    {
      type: SelectField,
      opts: {
        property: "type",
        rules: ["required"],
        default: "standard",
        options: {
          standard: "Standard",
          expanded: "Expanded",
        },
      },
    },

    {
      type: RelationshipField,
      opts: {
        property: "step",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "series",
        isPrimaryLabelField: 1,
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["series", "step", "identifier"],
      filterFields: ["type", "series", "step.step_type", "identifier"],
      searchLabel: "Search by name or identifier",
      // defaultSort: [
      //   {
      //     field: "id",
      //     order: "asc"
      //   }
      // ]
    },
    {
      id: "stepEdit",
      columnFields: ["identifier", "description", "type"],
      // searchLabel: "Search by name or identifier",
      useModal: true,
      modalOptions: {
        readmodeDefault: false,
      },
      defaultSort: [
        {
          field: "identifier",
          order: "asc",
        },
      ],
    },
  ];
}
