import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  RelationshipFieldMany,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";

export class ConsolidatedMappingStep extends CrudModel {
  protected static _typeLabel = "Custom Mapping Step";
  protected static asProperty = "consolidated_mapping_step";
  public static api = {
    path: "consolidated-mapping-steps",
    fullCache: true
  };
  protected static routeBase = "consolidated-mapping-steps";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: { name: "id", userPermissions: UserPermission.Hidden }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: { label: "Name", name: "name", rules: ["required"], maxLen: 55 }
    },
    {
      type: StringProperty,
      opts: {
        label: "Shortname",
        name: "shortname",
        rules: ["required"],
        maxLen: 16
      }
    },
    { type: StringProperty, opts: { label: "Comments", name: "comments" } },
    {
      type: RelationshipProperty,
      opts: {
        name: "fallback_value",
        relatedModel: "MappingStepValue",
        label: "Fallback Value",
        description:
          "The value to use if the mapping step does not return a value."
      }
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "consolidated_mapping_step_values",
        relatedModel: "ConsolidatedMappingStepValue",
        label: "Mapping Step Values",
        foreignProperty: "consolidated_mapping_step"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: { property: "created_at", userPermissions: UserPermission.Hidden }
    },
    {
      type: DateTimeField,
      opts: { property: "updated_at", userPermissions: UserPermission.Hidden }
    },
    {
      type: StringField,
      opts: { property: "name" }
    },
    {
      type: StringField,
      opts: { property: "shortname", isPrimaryLabelField: true }
    },
    { type: LongTextField, opts: { property: "comments" } },
    {
      type: "ModelLayoutField",
      opts: {
        property: "fallback_value",
        cols: 6
      }
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "consolidated_mapping_step_values",
        formComponentName: "form-relationship-field-many-table",
        cols: 6,
        requiresDetachedLabel: true
      }
    }
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "crud",
      fields: [
        "name",
        "shortname",
        "comments",
        "consolidated_mapping_step_values",
        "fallback_value"
      ]
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: ["name", "shortname", "comments"]
    }
  ];
}
