import {
  CollectionLayout,
  CollectionLayoutDefinition,
  CollectionLayoutOpts
} from "../CollectionLayout";

export interface IExpansionComponentDef {
  name?: string;
  componentOpts?: object;
}

export interface DataTableLayoutDefinition extends CollectionLayoutDefinition {
  inlineEdit?: boolean;
  inlineEditFields?: string[];
  expansionComponent?: IExpansionComponentDef;
  enableMultiSort?: boolean;
  dense?: boolean;
  disableRowClick?: boolean;
  rowClassProperties?: string[];
}

export interface DataTableLayoutOpts
  extends CollectionLayoutOpts,
    DataTableLayoutDefinition {}

export class DataTableLayout extends CollectionLayout {
  public static componentName = "data-table-layout";

  public expansionComponent: IExpansionComponentDef = {};
  public enableMultiSort = false;
  public dense;
  public disableRowClick = false;
  public rowClassProperties: string[] = [];

  constructor(opts: DataTableLayoutOpts) {
    super(opts);

    if (typeof opts.expansionComponent !== "undefined")
      this.expansionComponent = opts.expansionComponent;

    if (typeof opts.enableMultiSort !== "undefined")
      this.enableMultiSort = opts.enableMultiSort;

    if (typeof opts.dense !== "undefined") this.dense = opts.dense;
    else this.dense = this.$nuxt.$config.denseTables;

    if (typeof opts.rowClassProperties !== "undefined")
      this.rowClassProperties = opts.rowClassProperties;

    if (typeof opts.disableRowClick !== "undefined")
      this.disableRowClick = opts.disableRowClick;
  }

  public get expansionEnabled() {
    return this.expansionComponent && this.expansionComponent.name;
  }
}
