import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";

export class LeadTime extends CrudModel {
  protected static _typeLabel = "Lead Time";
  protected static asProperty = "lead_time";
  public static api = {
    path: "lead-times",
    fullCache: true
  };
  protected static routeBase = "lead-times";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "label",
        label: "Label"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "legacy_leadtime_id",
        label: "Legacy Lead Time ID"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_25",
        label: "25"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_50",
        label: "50"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_100",
        label: "100"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_250",
        label: "250"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_500",
        label: "500"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_1000",
        label: "1,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_2500",
        label: "2,500"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_5000",
        label: "5,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_10000",
        label: "10,000"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "lt_25000",
        label: "25,000"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringField,
      opts: {
        property: "label",
        isPrimaryLabelField: true
      }
    },
    {
      type: StringField,
      opts: {
        property: "legacy_leadtime_id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_25"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_50"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_100"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_250"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_500"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_1000"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_2500"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_5000"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_10000"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "lt_25000"
      }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "default",
      useModal: true,
      enableSearch: true,
      searchLabel: "Search by label",
      modalOptions: {
        readmodeDefault: false
      },
      columnFields: [
        "label",
        "lt_25",
        "lt_50",
        "lt_100",
        "lt_250",
        "lt_500",
        "lt_1000",
        "lt_2500",
        "lt_5000",
        "lt_10000",
        "lt_25000"
      ]
    }
  ];
}
