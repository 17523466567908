import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  MoneyField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  MoneyProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { utils as taxonomyUtils } from "./priced-component-taxonomies";

export class PricedComponent extends CrudModel {
  protected static _typeLabel = "Priced Component";
  protected static _listTitle = "Pricing Table";
  protected static asProperty = "priced_component";
  public static api = {
    path: "priced-components",
  };
  protected static routeBase = "priced-components";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Read
  );
  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Last Updated",
        userPermissions: UserPermission.Read,
        sortable: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Part Number",
        name: "part_number",
        rules: ["required"],
        sortable: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Description",
        name: "description",
        sortable: true,
      },
    },
    {
      type: MoneyProperty,
      opts: {
        label: "Price",
        name: "price",
        decimals: 3,
        rules: ["required"],
        sortable: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Class",
        name: "class",
        sortable: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "MOQ",
        name: "moq",
        sortable: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Setup",
        name: "setup",
        sortable: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Pin Count",
        name: "pin_count",
        sortable: true,
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        label: "OEM Factor",
        relatedModel: "Factor",
        name: "factor",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Distributor Discount Factor",
        relatedModel: "Factor",
        name: "discount_group",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "PML",
        relatedModel: "Pml",
        name: "pml",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Lead Time",
        relatedModel: "LeadTime",
        name: "lead_time",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Price Book",
        relatedModel: "PriceBook",
        name: "price_book",
        rules: ["required"],
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "price_queries",
        relatedModel: "PriceQuery",
        label: "Price Queries",
        foreignProperty: "priced_components",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "audit_logs",
        relatedModel: "AuditLog",
        label: "Audit Logs",
        foreignProperty: "priced_component",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "priced_component_import",
        relatedModel: "PricedComponentImport",
        userPermissions: UserPermission.Hidden,
      },
    },

    ...taxonomyUtils.getAsPropertyDefs(),
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringField,
      opts: {
        property: "part_number",
        isPrimaryLabelField: true,
      },
    },
    {
      type: StringField,
      opts: {
        property: "description",
      },
    },
    {
      type: MoneyField,
      opts: {
        property: "price",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "class",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "moq",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "setup",
      },
    },
    {
      type: NumberField,
      opts: {
        property: "pin_count",
      },
    },

    {
      type: RelationshipField,
      opts: {
        property: "factor",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "discount_group",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "pml",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "lead_time",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "price_book",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "priced_component_import",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "audit_logs",
        formComponentName: "form-relationship-field-many-table",
        viewComponentName: "form-relationship-field-many-table",
      },
    },

    ...taxonomyUtils.getAsFieldDefs(),

    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useDynamicFields: false,
      enableDynamicFilters: true,
      useModal: true,
      modalOptions: {
        readmodeDefault: false,
      },
      filterFields: ["price_book"],
      filterProperties: ["priced_component_import"],
      enableSearch: true,
      searchLabel: "Search by part or description",
    },
    {
      id: "as-relationship",
      useModal: true,
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      headerComponents: ["duplicate-model"],
      fieldSets: [
        {
          id: "0",
          fields: [
            ,
            "price_book",
            "part_number",
            "description",
            "price",
            "class",
            "moq",
            "setup",
            "pin_count",
            "factor",
            "discount_group",
            "pml",
            "lead_time",
            "product_group",
            "product_category",
            "price_group",
            "positions_number",
            "termination",
            "gender",
            "military_class",
            "mount_angle",
          ],
        },
        {
          id: "1",
          fields: ["audit_logs", "id", "created_at", "updated_at"],
        },
      ],
    },
  ];
}
