import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";

export class Currency extends CrudModel {
  protected static _typeLabel = "Currency";
  protected static asProperty = "currency";
  public static api = {
    path: "currencies",
    fullCache: true
  };
  protected static routeBase = "currencies";

  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: {
        label: "Name",
        name: "name"
      }
    },
    {
      type: StringProperty,
      opts: {
        label: "Symbol",
        name: "symbol"
      }
    },
    {
      type: NumberProperty,
      opts: {
        label: "Exchange Rate",
        name: "exchange_rate"
      }
    },
    {
      type: StringProperty,
      opts: {
        label: "Comments",
        name: "comments"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true
      }
    },
    {
      type: StringField,
      opts: {
        property: "symbol"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "exchange_rate"
      }
    },
    {
      type: LongTextField,
      opts: {
        property: "comments"
      }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true
    }
  ];
}
