import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  WysiwygField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class DocsArticle extends CrudModel {
  protected static _typeLabel = "Documentation Article";
  protected static _typeLabelPlural = "Documentation";
  protected static asProperty = "docs-article";
  public static api = {
    path: "docs-articles",
  };
  protected static routeBase = "docs-articles";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Read,
    },
    UserPermission.Read,
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Title",
        name: "title",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Slug",
        name: "slug",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Content",
        name: "content",
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Order",
        name: "order",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "parent",
        label: "Parent Article",
        relatedModel: "DocsArticle",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "children",
        label: "Sub-Articles",
        relatedModel: "DocsArticle",
        foreignProperty: "parent",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringField,
      opts: {
        property: "title",
        isPrimaryLabelField: true,
        cols: 12,
      },
    },
    {
      type: StringField,
      opts: {
        property: "slug",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberField,
      opts: {
        property: "order",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: WysiwygField,
      opts: {
        property: "content",
        cols: 12,
      },
    },
    {
      type: RelationshipField,
      opts: { property: "parent", userPermissions: UserPermission.Hidden },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "children",
        userPermissions: UserPermission.Hidden,
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["title"],
      enableSearch: true,
      useModal: true,
      modalOptions: { readmodeDefault: true },
      searchLabel: "Search",
      noRefresh: true,
      // ordinalField: "order",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  ];
}
