import { ComputedPriceField, ConditionalField } from "../fields";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  SelectField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  JsonProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";

export class DynamicAdder extends CrudModel {
  protected static _typeLabel = "Gold Adder";
  protected static asProperty = "dynamic-adder";
  public static api = {
    path: "dynamic-adders"
  };

  protected static routeBase = "dynamic-adders";

  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Name"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "part_number",
        label: "Part Number"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "dynamic_price",
        label: "Computed Price"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "legacy_id",
        label: "Legacy ID"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "type",
        label: "Adder Type"
      }
    },
    {
      type: BooleanProperty,
      opts: {
        name: "apply_to_direct_matches",
        label: "Applies to Direct Matches",
        description:
          "If the part number is found in the price book directly, should this adder still be applied?"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "additional_lead_time",
        label: "Additional Leadtime"
      }
    },
    {
      type: JsonProperty,
      opts: {
        name: "conditional",
        label: "Conditions"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Price Book",
        relatedModel: "PriceBook",
        name: "price_book"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Parsing Schema",
        relatedModel: "PricingSchema",
        name: "pricing_schema"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true
      }
    },
    {
      type: StringField,
      opts: {
        property: "part_number"
      }
    },
    {
      type: ComputedPriceField,
      opts: {
        property: "dynamic_price",
        md: 4
      }
    },
    {
      type: SelectField,
      opts: {
        property: "type",
        options: { base: "Base", setup: "Setup" },
        description:
          "Base: Adder is applied to the base price. Setup: Adder is spread out across each part's price."
      }
    },
    // {
    //   type: BooleanField,
    //   opts: {
    //     property: "apply_to_direct_matches"
    //   }
    // },
    {
      type: ConditionalField,
      opts: {
        property: "conditional"
      }
    },
    {
      type: StringField,
      opts: {
        property: "legacy_id"
      }
    },
    {
      type: RelationshipField,
      opts: {
        property: "price_book"
      }
    },
    {
      type: RelationshipField,
      opts: {
        property: "pricing_schema"
      }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      enableSearch: true,
      filterFields: ["price_book", "type"],
      columnFields: [
        "part_number",
        "dynamic_price",
        "type",
        "legacy_id",
        "price_book"
      ]
    }
  ];
}
