import { CrudField } from "../plugins/Cruxtify/CrudField";

export class MappingStepValueResolvedField extends CrudField {
  public static fieldName = "MappingStepValueResolvedField";
  public static viewComponentName: string = "view-read-only";

  public get() {
    const valueType = this.model?.findProperty("value_type")?.get(true);
    switch (valueType) {
      case "constant":
        return this.model?.findProperty("constant")?.get();
      case "step":
        return this.model?.findProperty("step_type")?.get();
      case "consolidated":
        return this.model?.findProperty("consolidated_mapping_step")?.get();
    }

    return null;
  }
}
