import { CrudPropertyGet } from "~/plugins/Cruxtify/CrudProperty";
import { CrudField } from "../plugins/Cruxtify/CrudField";

export class ConditionalField extends CrudField {
  public static fieldName = "ConditionalField";
  public static formComponentName: string = "conditional-field";

  public cols = 12;
  protected _label: string = "Conditions";
  protected _requiresDetachedLabel: boolean = true;

  public get(opts?: CrudPropertyGet) {
    if (opts) return super.get(opts);

    const jsonValue = this.property.get(true);
    if (!jsonValue || jsonValue.length === 0) return "No Condition Defined";

    return "Condition Defined (edit to view)";
  }
}
