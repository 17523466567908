
export default {
  data() {
    return {};
  },
  props: {
    itemLabel: {
      type: String,
      default: ""
    },
    open: {
      type: Boolean,
      default: false
    },
    confirmLabel: {
      type: String,
      default: "Confirm"
    },
    cancelLabel: {
      type: String,
      default: "Cancel"
    },
    confirmProps: {
      type: Object,
      default: () => ({})
    },
    cancelProps: {
      type: Object,
      default: () => ({ color: "blue darken-1", text: true })
    }
  },
  computed: {
    hasText() {
      return (
        this.$slots["text"] !== undefined ||
        this.$scopedSlots["text"] !== undefined
      );
    }
  }
};
