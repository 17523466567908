
import _ from "lodash";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      items: [],
      options: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: this.$config.defaultCollectionPerPage
          ? this.$config.defaultCollectionPerPage
          : this.layout.perPage,
      },
      collectionQueryOptsMap: {
        sortBy: "sortBy",
        sortDesc: "sortDesc",
        page: "page",
        itemsPerPage: "perPage",
      },
      isSortMode: this.layout.ordinalField && !this.layout.expansionEnabled,
      saveOnEdit: false,
      editedIndex: -1,
      editedItem: {},
      expanded: [],
      dense: this.layout.dense,
      query: null,
      tableRenderKey: 0,
      bubbleTableRowEvents: {
        "edit-item": (item) => this.$emit("edit-item", item),
        "delete-item": (item) => this.$emit("delete-item", item),
        "restore-item": (item) => this.$emit("restore-item", item),
      },
    };
  },

  props: {
    layout: {
      type: Object,
      required: true,
    },
    showModelActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateRowItems() {
      this.items = this.layout.collection.instances.map((instance) => {
        return this.buildDataTableRow(instance);
      });
    },

    onModalSaved() {
      // TODO: REFACTOR
      // this.redrawRowFromModel(this.singleModel);
    },

    buildDataTableRow(instance) {
      return { id: instance.id, instance, vueKey: 0 };
    },

    getRowIndex(rowData) {
      return this.items.findIndex((item) => item.id == rowData.id);
    },

    forceTableRedraw() {
      this.tableRenderKey++;
    },

    redrawRowFromModel(model) {
      const rowData = this.buildDataTableRow(model);
      this.updateRow(rowData);
    },

    updateRow(rowData, updatedProperties = {}) {
      const newRowData = Object.assign(
        rowData,
        {
          vueKey: Date.now(),
        },
        updatedProperties
      );

      const itemIndex = this.getRowIndex(rowData);

      if (itemIndex == -1) this.items.unshift(newRowData);
      else this.items.splice(itemIndex, 1, newRowData);

      this.forceTableRedraw();
    },

    updateOrder(e) {
      if (e.oldIndex === e.newIndex) return;

      // update everything between oldIndex and newIndex
      const lowIndex = Math.min(e.oldIndex, e.newIndex);
      const highIndex = Math.max(e.oldIndex, e.newIndex);

      this.items.forEach((item, ind) => {
        if (ind < lowIndex || ind > highIndex) return;

        item.instance.setField(this.layout.ordinalField, ind);
      });
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newVal, oldVal) {
        // key: datatable options
        // value: layout.collection query option

        const changedProps = Object.keys(newVal).filter(
          (key) => !_.isEqual(newVal[key], oldVal[key])
        );

        changedProps.forEach((changedProp) => {
          const collectionOption = this.collectionQueryOptsMap[changedProp];

          let queryValue = newVal[changedProp];

          this.layout.collection.setQueryOption(collectionOption, queryValue);
        });
      },
    },
    "layout.collection.remoteQueryOptions": {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        // key: layout.collection query option
        // value: datatable options

        const changedProps = !oldVal
          ? Object.keys(newVal)
          : Object.keys(newVal).filter(
              (key) => !_.isEqual(newVal[key], oldVal[key])
            );

        changedProps.forEach((changedProp) => {
          const datatableOption = Object.keys(this.collectionQueryOptsMap).find(
            (key) => this.collectionQueryOptsMap[key] == changedProp
          );

          let queryValue = newVal[changedProp];

          // cast sortby to matching field name
          if (changedProp == "sortBy") {
            const matchingColumns = queryValue
              .map((sortByProperty) =>
                this.columns.find(
                  (column) =>
                    column.field &&
                    column.field.property.name == sortByProperty.name
                )
              )
              .filter((val) => val);

            // abort if no matching columns
            if (!matchingColumns.length) return;

            queryValue = matchingColumns.map((col) => col.value);
          }

          this.options[datatableOption] = queryValue;
        });
      },
    },
    loadedValuesProxy: {
      immediate: true,
      handler(val) {
        this.updateRowItems();
      },
    },
    "options.itemsPerPage": {
      immediate: true,
      handler(val) {
        if (val == -1 || val > 250) this.layout.useDynamicFields = false;
      },
    },
  },
  mounted() {
    this.layout.collection.fetchIfUnhydrated();
  },
  computed: {
    columns() {
      const fieldsByRelativeId =
        this.layout.collection.model.findFieldsByRelativeId(
          this.layout.columnFields
        );

      const columns = Object.keys(fieldsByRelativeId)
        // hide static field filters
        .filter((fieldId) => {
          if (
            !this.layout.collection.staticFilters ||
            this.layout.showStaticFilterColumns
          )
            return true;

          return !this.layout.collection.staticFilters.find(
            (filter) => filter.queryName == fieldId
          );
        })
        // hide hidden fields
        .filter((fieldId) => {
          const field = fieldsByRelativeId[fieldId];
          return field.isVisibleToUser(null, false);
        })
        .map((fieldId) => {
          const field = fieldsByRelativeId[fieldId];
          const isModelOwnField = fieldId.indexOf(".") === -1;

          return {
            text: field.label,
            value: fieldId,
            sortable: this.layout.ordinalField
              ? false
              : field.property.sortable,
            itemSlot: "item." + fieldId,
            isPrimaryLabelField: field.isPrimaryLabelField && isModelOwnField,
            field,
          };
        });

      if (
        // built-in actions
        this.layout.enabledItemActions.length > 0 ||
        // slot actions
        !!this.$slots["item-actions"] ||
        !!this.$scopedSlots["item-actions"]
      )
        columns.push({ text: "", value: "_item-actions", sortable: false });

      if (
        // slot actions
        this.layout.expansionEnabled ||
        this.layout.ordinalField
      )
        columns.unshift({ text: "", value: "_table-action", sortable: false });

      return columns;
    },
    showSortToggle() {
      return this.layout.expansionEnabled && this.layout.ordinalField;
    },
    hideFooter() {
      return (
        this.layout.collection.totalItems <= this.options.itemsPerPage ||
        this.options.itemsPerPage == -1
      );
    },
    perPageOpts() {
      const perPageOpts = [25, 50, 100, 500, 1000];
      if (this.layout.collection.totalItems <= 250) perPageOpts.push(-1);
      return perPageOpts;
    },
    // taken from https://stackoverflow.com/questions/42737034/vue-js-watch-multiple-properties-with-single-handler#answer-45853349
    loadedValuesProxy() {
      return (
        "" +
        this.layout.collection.newInstances.length +
        "|" +
        this.layout.collection.instances.length +
        "|" +
        this.layout.collection.ids.join("|")
      );
    },
  },
};
