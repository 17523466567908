import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";
import { SelectField } from "../plugins/Cruxtify/field-types";
import { BooleanField } from "../plugins/Cruxtify/field-types/BooleanField";
import { DateTimeField } from "../plugins/Cruxtify/field-types/DateTimeField";
import { EmailField } from "../plugins/Cruxtify/field-types/EmailField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  BooleanProperty,
  DateTimeProperty,
  EmailProperty,
  NumberProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";

export class User extends CrudModel {
  protected static _typeLabel = "User";
  protected static asProperty = "user";
  public static api = {
    path: "users"
  };

  protected userIdField = ["id"];
  protected static routeBase = "users";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Read
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true
      }
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_admin",
        label: "Admin Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit
          },
          UserPermission.Hidden
        )
      }
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_super_user",
        label: "Super User Account",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit
          },
          UserPermission.Hidden
        )
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Name",
        sortable: true,
        rules: ["required"]
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "customer_type",
        label: "Customer Type",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Email",
        sortable: true,
        rules: ["required"]
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "password",
        label: "Password (leave blank to ignore)",
        sortable: false
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "company",
        label: "Company",
        sortable: true,
        userPermissions: UserPermission.Hidden
      }
    }
  ];

  protected static fieldDefinitions = [
    { type: StringField, opts: { property: "name" } },
    { type: EmailField, opts: { property: "email", isPrimaryLabelField: 1 } },
    { type: StringField, opts: { property: "password" } },
    { type: StringField, opts: { property: "company" } },
    {
      type: SelectField,
      opts: {
        property: "customer_type",
        options: ["OEM", "Distributor"]
      }
    },
    { type: NumberField, opts: { property: "id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: BooleanField, opts: { property: "is_admin" } },
    { type: BooleanField, opts: { property: "is_super_user" } }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      enableSearch: true,
      useModal: true,
      searchLabel: "Search by name or email",
      columnFields: ["name", "email", "is_admin", "is_super_user"],
      defaultSort: [
        {
          field: "name",
          order: "asc"
        }
      ]
    }
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "table",
      fieldSets: [
        {
          id: "0",
          fields: ["name", "email", "password"]
        },
        {
          id: "1",
          fields: ["is_admin", "is_super_user"]
        },
        {
          id: "2",
          fields: ["created_at", "updated_at"]
        }
      ]
    }
  ];
}
