
import { Currency, PriceBook } from "~/models";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },

      pricebooks: [],
      currencies: [],

      singlePartNumber: "",
      singleCurrency: "USD",
      singleCurrencySymbol: "",
      singlePricebook: null,

      singleLoading: false,
      singleJsonResult: false,
    };
  },
  methods: {
    singleSubmit() {
      if (!this.$refs.singleForm.validate()) return false;

      this.singleLoading = true;

      return this.$nuxt.$api
        .GET(
          "price-part",
          {
            username: this.$auth.user.email,
            pricebook: this.singlePricebook,
            part: this.singlePartNumber,
            currency: this.singleCurrency,
            customer_type: this.$auth.user.customer_type,
            source: "pricing-tool",
            is_ephemeral: true,
            monitor_logic: true,
          },
          "api/",
          {
            validateStatus: null, // ignore global error interceptors
          }
        )
        .then((res) => {
          this.singleError = false;
          this.singleJsonResult = res.data;
        })
        .finally((res) => (this.singleLoading = false));
    },

    openSingleDialog() {
      this.singleResultDialogOpen = true;
      // this.$refs.singleDialogContent.scrollTop = 0;
    },

    decoratePrice(price) {
      if (!price) return "";

      return (
        this.singleCurrencySymbol + Number(price).toFixed(2).toLocaleString()
      );
    },

    closeDebugger() {
      this.$emit("close");
    },
  },
  mounted() {
    PriceBook.get().then((res) => {
      this.pricebooks = res.dataHydrated.map((model) => {
        return {
          text: model.label,
          value: model.prop("shortname"),
          created: model.prop("created_at", true),
        };
      });

      this.singlePricebook = this.pricebooks.reduce((max, pricebook) =>
        max && max.created > pricebook.created ? max : pricebook
      ).value;
    });

    Currency.get().then((res) => {
      this.currencies = res.dataHydrated.map((model) => model.label);
    });
  },
  computed: {
    hasOem() {
      return (
        this.singlePricingResults &&
        this.singlePricingResults[0] &&
        typeof this.singlePricingResults[0].oem !== "undefined"
      );
    },
    hasGroup() {
      return (
        this.singlePricingResults &&
        this.singlePricingResults[0] &&
        typeof this.singlePricingResults[0].group !== "undefined"
      );
    },
  },
};
