import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  SelectField,
  StringField,
  RelationshipField,
  BooleanField,
  MoneyField
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  StringProperty,
  RelationshipProperty,
  MoneyProperty,
  BooleanProperty,
  RelationshipPropertyMany,
  FileProperty
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";

export class PricedComponentImport extends CrudModel {
  protected static _typeLabel = "Priced Component Import";
  protected static asProperty = "priced_component_import";
  public static api = {
    path: "priced-component-imports"
  };
  protected static routeBase = "priced-component-imports";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },

    {
      type: DateTimeProperty,
      opts: {
        name: "imported_date",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: FileProperty,
      opts: {
        name: "input_file"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "num_components"
      }
    },
    {
      type: NumberProperty,
      opts: {
        name: "num_imported"
      }
    },
    {
      type: StringProperty,
      opts: {
        name: "original_filename"
      }
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_replace"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "price_book",
        relatedModel: "PriceBook",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "priced_components",
        relatedModel: "PricedComponent",
        foreignProperty: "priced_component_import",
        userPermissions: UserPermission.Hidden
      }
    }
  ];
}
