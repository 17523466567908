import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  LongTextField,
  NumberField,
  RelationshipField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class AuditLog extends CrudModel {
  protected static _typeLabel = "Audit Log";
  protected static _typeLabelPlural = "Audit Logs";
  protected static asProperty = "audit_log";
  public static api = {
    path: "audit-logs",
    fullCache: true,
  };
  protected static routeBase = "audit-logs";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Read,
      [UserRole.SuperUser]: UserPermission.Read,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Timestamp",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Action",
        name: "action",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "Priced Component",
        relatedModel: "PricedComponent",
        name: "priced_component",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        label: "User",
        relatedModel: "User",
        name: "user",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        isPrimaryLabelField: 1,
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "priced_component",
        isPrimaryLabelField: 2,
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "user",
        isPrimaryLabelField: 3,
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "action",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
