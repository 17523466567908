import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  ModelLayoutField,
  NumberField,
  RelationshipField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  JsonProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";

export class ConsolidatedMappingStepValue extends CrudModel {
  protected static _typeLabel = "Custom Mapping Step Value";
  protected static asProperty = "consolidated_mapping_step_value";
  public static api = {
    path: "consolidated-mapping-step-values"
  };
  protected static routeBase = "consolidated-mapping-step-values";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: { name: "id", userPermissions: UserPermission.Hidden }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: { label: "Name", name: "name" }
    },
    { type: JsonProperty, opts: { name: "conditional" } },
    {
      type: RelationshipProperty,
      opts: {
        name: "mapping_step_value",
        relatedModel: "MappingStepValue",
        label: "Mapping Step Value"
      }
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "consolidated_mapping_step",
        relatedModel: "ConsolidatedMappingStep",
        label: "Custom Mapping Step"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: { property: "created_at", userPermissions: UserPermission.Hidden }
    },
    {
      type: DateTimeField,
      opts: { property: "updated_at", userPermissions: UserPermission.Hidden }
    },
    {
      type: StringField,
      opts: { property: "name", isPrimaryLabelField: true }
    },

    { type: "ConditionalField", opts: { property: "conditional" } },

    {
      type: ModelLayoutField,
      opts: {
        property: "mapping_step_value",
        seamless: true
      }
    },

    {
      type: RelationshipField,
      opts: {
        property: "consolidated_mapping_step"
      }
    }
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "modal",
      fields: ["name", "mapping_step_value", "conditional"]
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      modalOptions: {
        readmodeDefault: false
      },
      columnFields: ["name", "mapping_step_value"]
    }
  ];
}
