import { CrudModel } from "../../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  StringField
} from "../../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  StringProperty
} from "../../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../../plugins/Cruxtify/UserPermission";

export class PricedComponentTaxonomyBase extends CrudModel {
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit
    },
    UserPermission.Read
  );

  // public static modalEdit = false;
  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: {
        label: "Name",
        name: "name"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true
      }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: true
    }
  ];
}

export class ProductGroup extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Product Group";
  protected static asProperty = "product_group";
  public static api = {
    path: "product-groups",
    fullCache: true
  };
  protected static routeBase = "product-groups";
}

export class ProductCategory extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Product Category";
  protected static asProperty = "product_category";
  public static api = {
    path: "product-categories",
    fullCache: true
  };
  protected static routeBase = "product-categories";
}

export class PriceGroup extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Price Group";
  protected static asProperty = "price_group";
  public static api = {
    path: "price-groups",
    fullCache: true
  };
  protected static routeBase = "price-groups";
}

export class PositionsNumber extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Number of Positions";
  protected static asProperty = "positions_number";
  public static api = {
    path: "positions-numbers",
    fullCache: true
  };
  protected static routeBase = "positions-numbers";
}

export class Gender extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Gender";
  protected static asProperty = "gender";
  public static api = {
    path: "genders",
    fullCache: true
  };
  protected static routeBase = "genders";
}

export class Termination extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Termination";
  protected static asProperty = "termination";
  public static api = {
    path: "terminations",
    fullCache: true
  };
  protected static routeBase = "terminations";
}

export class MountAngle extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Mount Angle";
  protected static asProperty = "mount_angle";
  public static api = {
    path: "mount-angles",
    fullCache: true
  };
  protected static routeBase = "mount-angles";
}

export class MilitaryClass extends PricedComponentTaxonomyBase {
  protected static _typeLabel = "Military Class";
  protected static asProperty = "military_class";
  public static api = {
    path: "military-classes",
    fullCache: true
  };
  protected static routeBase = "military-classes";
}

const taxonomies = {
  ProductGroup: ProductGroup,
  ProductCategory: ProductCategory,
  PriceGroup: PriceGroup,
  PositionsNumber: PositionsNumber,
  Gender: Gender,
  Termination: Termination,
  MountAngle: MountAngle,
  MilitaryClass: MilitaryClass
};

export const utils = {
  getAsPropertyDefs: () => {
    return Object.keys(taxonomies).map(taxonomyName => {
      return {
        type: "RelationshipProperty",
        opts: {
          label: taxonomies[taxonomyName].typeLabel,
          relatedModel: taxonomyName,
          name: taxonomies[taxonomyName].asProperty
        }
      };
    });
  },
  getAsFieldDefs: () => {
    return Object.keys(taxonomies).map(taxonomyName => {
      return {
        type: "RelationshipField",
        opts: {
          property: taxonomies[taxonomyName].asProperty
        }
      };
    });
  },
  getAsPropertyNames: () => {
    return Object.keys(taxonomies).map(taxonomyName => {
      return taxonomies[taxonomyName].asProperty;
    });
  }
};
