import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  NumberField,
  StringField
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  StringProperty
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole
} from "../plugins/Cruxtify/UserPermission";

export class Pml extends CrudModel {
  protected static _typeLabel = "PML";
  protected static _typeLabelPlural = "PMLs";
  protected static asProperty = "pml";
  public static api = {
    path: "pmls",
    fullCache: true
  };
  protected static routeBase = "pmls";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringProperty,
      opts: {
        label: "Name",
        name: "fullname"
      }
    },
    {
      type: StringProperty,
      opts: {
        label: "Shortname",
        name: "shortname"
      }
    },
    {
      type: NumberProperty,
      opts: {
        label: "Additional Leadtime",
        name: "additional_leadtime"
      }
    }
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        userPermissions: UserPermission.Hidden
      }
    },
    {
      type: StringField,
      opts: {
        property: "fullname",
        isPrimaryLabelField: true
      }
    },
    {
      type: StringField,
      opts: {
        property: "shortname"
      }
    },
    {
      type: NumberField,
      opts: {
        property: "additional_leadtime"
      }
    }
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: true
    }
  ];
}
