import Vue from "vue";

import { CrudModel } from "./CrudModel";
import { UserPermissions } from "./UserPermission";
import { CrudField } from "./CrudField";

import * as components from "./components";
import * as fieldTypes from "./field-types";
import * as layouts from "./layouts";
import * as propertyTypes from "./property-types";
import * as conditionals from "./conditionals";
import * as conditionalComparers from "./conditional-comparers";
import validationRules from "./validation-rules";

import Rule from "./Rule";
import { CrudProperty } from "./CrudProperty";
import { CrudLayout } from "./CrudLayout";
import { CrudCollection } from "./CrudCollection";
import { Conditional } from "./Conditional";

export function initConfig(userCrudObjects, context = { $auth: {}, $api: {} }) {
  // context
  CrudModel.setNuxtContext(context);
  CrudField.setNuxtContext(context);
  CrudLayout.setNuxtContext(context);
  CrudProperty.setNuxtContext(context);
  CrudCollection.setNuxtContext(context);
  UserPermissions.setNuxtContext(context);

  // user models
  CrudModel.registerModelTypes({ ...userCrudObjects.crudModels });
  CrudField.registerCrudFieldTypes({ ...userCrudObjects.crudFields });

  // property types
  Object.keys(propertyTypes).forEach(propertyTypeName => {
    CrudProperty.registerCrudPropertyType(
      propertyTypeName,
      propertyTypes[propertyTypeName]
    );
  });

  // field types
  Object.keys(fieldTypes).forEach(fieldTypeName => {
    CrudField.registerCrudFieldType(fieldTypeName, fieldTypes[fieldTypeName]);
  });

  // layouts
  Object.keys(layouts).forEach(layoutName => {
    CrudLayout.registerCrudLayoutType(layoutName, layouts[layoutName]);
  });

  // components
  Object.keys(components).forEach(name => {
    Vue.component(name, components[name]);
    Vue.component(toKebab(name), components[name]);
  });

  // conditional comparers
  Conditional.registerConditionalTypes(conditionals);
  conditionals.CompareConditional.registerComparers(conditionalComparers);

  // rules
  Rule.registerRules(validationRules);
}

// https://www.geeksforgeeks.org/how-to-convert-a-string-into-kebab-case-using-javascript/
const toKebab = str =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .join("-")
    .toLowerCase();
